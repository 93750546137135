@import "../imports";


/*#region Fonts */
.u-primaryFont {
    font-family: $headings-font-family;
}

.u-secondaryFont {
    font-family: $font-family-sans-serif;
}

.u-tertiaryFont {
    font-family: $font-family-serif;
    font-style: italic;
}
/*#endregion*/

/*#region Text */
.u-heading {
    @extend .u-primaryFont;
    font-weight: 700;
}
/*#endregion*/

/*#region Font sizes */
// Class name value corresponds to desktop font size in pixels.
.u-textSize12 {
    font-size: .612rem;
}

.u-textSize14 {
    font-size: .875rem;
}

.u-textSize18 {
    font-size: 1.125rem;
}

.u-textSize32 {
    font-size: 1.05rem;

    @include media-breakpoint-up(sm) {
        font-size: 1.75rem;
    }
}

.u-textSize64 {
    @include media-breakpoint-up(sm) {
        font-size: 4.5rem;
    }
}
/*#endregion*/

/*#region Font styles */
.u-textShadow {
    text-shadow: 0 0 .5rem rgba($black,.6);
}
/*#endregion*/


/*#region Carousel */
.u-carouselIndicators {
    list-style-type: none;
    bottom: auto;

    > li {
        background-color: rgba($gray-600, .7);
    }
}

.u-carouselIndicator {
    border-radius: 50%;
    width: 10px !important;
    height: 10px !important;
    background-color: $carousel-indicator-bg;
    cursor: pointer;

    &.active {
        background-color: $carousel-indicator-active-bg;
    }
}

/*#endregion*/

/*#region Lazy load */
img[data-src].u-lazyAnimated, img[data-srcset].u-lazyAnimated {
    filter: blur(10px);
    transform-style: preserve-3d;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
}

.u-fadeIn.u-lazyAnimated {
    transition: filter .2s ease-in;
    transition-timing-function: steps(2, end);
    filter: blur(0) !important;
}
/*#endregion*/

.u-limitPageWidth {
    max-width: 1200px;
}

.u-no-mw {
    max-width: none !important;
}

.u-showHideList {
    &.is-hidden {
        display: none;

        @include media-breakpoint-up(lg) {
            display: block;
        }
    }
}

.u-btnUnstyled {
    background-color: transparent;
    padding: 0;
    margin: 0;
    border: 0;
    cursor: pointer;
}

.u-limitHeightWrapper {
    overflow-y: hidden;
    position: relative;

    &.is-truncated {

        &::after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            right: 0;
            height: 3rem;
            pointer-events: none;
            @include gradient-y($start-color: rgba(255,255,255,0), $end-color: $white)
        }
    }

    &--noGradient {
        &::after {
            content: none !important;
        }
    }
}

.u-bgLight {
    background-color: $sand-light;
}

.u-twoCols {
    @include media-breakpoint-up(md) {
        column-count: 2;
        column-gap: 30px;
    }
}

.u-threeCols {
    @include media-breakpoint-up(md) {
        column-count: 3;
        column-gap: 30px;
    }
}

.u-overflowHidden {
    overflow: hidden;
}

//Prevents the "Add to here" buttons being placed on top of each other in experience editor mode.
.u-addToHereButtonSpacer {
    padding-top: 1rem;
}

// Show empty placeholders in Sitecore Experience Editor
// Source: https://blog.krusen.dk/empty-placeholder-missing-using-flexbox/
.scEmptyPlaceholder {
    flex-basis: 100%;
}

// Sitecore forms validation error styling
.field-validation-error, .validation-summary-errors {
    margin-top: .25rem;
    font-size: .875rem;
    color: $danger;
}

.u-toggleMobileExecuteDesktopTarget, .toggleDesktopExecuteMobileTarget, .u-toggleAllTarget {
    @include media-breakpoint-down(lg) {
        display: none;

        &.is-visible {
            display: block;
        }
    }
}

.u-experienceEditorPreviewImage {
    position: relative;

    img {
        position: absolute;
        top: 2rem;
        left: 2rem;
        width: 150px;
        height: auto;
        border: 3px solid #fff;
        box-shadow: 0 0 10px rgba(0,0,0,.4);
        z-index: 1000;
    }

    &--small img {
        width: 50px;
    }
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
