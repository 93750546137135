﻿@import "../imports";

body:before {
    content: "xs";
    display: none;
}
@include media-breakpoint-only(sm) {
    body:before {
        content: "sm";
    }
}
@include media-breakpoint-only(md) {
    body:before {
        content: "md";
    }
}
@include media-breakpoint-only(lg) {
    body:before {
        content: "lg";
    }
}
@include media-breakpoint-only(xl) {
    body:before {
        content: "xl";
    }
}